import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { getallcleanerslotsdata, getallemployeesslotsdata, saveuserdata } from '../../utils/firebasefunctions';
import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { FaUser } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import {
    getDownloadURL,
    ref,
    uploadBytes,
    uploadBytesResumable
} from "firebase/storage";
import { storage, app } from '../../firebase.config'
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { RotatingLines } from 'react-loader-spinner'
import ProjectPopUp from '../components/ProjectPopUp';
import StatusPopup from '../components/StatusPopup';
import moment from 'moment';
import { FaRegCalendarAlt } from "react-icons/fa";
import DatePicker from "react-horizontal-datepicker";


import { RiPassValidLine } from 'react-icons/ri';
import Confirmationpopup from '../components/Confirmationpopup';
import EmployeeSlotPopUp from '../components/EmployeeSlotPopUp';
import { ImFilesEmpty } from "react-icons/im";
import HorizontalDatePicker from '../components/HorizontalDatePicker';


const Slots = (props) => {
    const [{ user, clients, employees }, dispatch] = useStateValue();
    const [checkinstatusfilter, setcheckinstatusfilter] = useState(
        [
          {
            title: "All",

          },
          {
            title: "Pending",

          },
          {
            title: "On The Way",

          },
          {
            title: "Checked In",

          },
          {
            title: "Checked Out",

          }
        ]
      );
      const daysofweek = [
        {
          day: "Sunday"
        },
        {
          day: "Monday"
        },
        {
          day: "Tuesday"
        },
        {
          day: "Wednesday"
        },
        {
          day: "Thursday"
        },
        {
          day: "Friday"
        },
        {
          day: "Saturday"
        },

      ]
      const [selectedproject, setselectedproject] = useState("All")
      const [selectedprojectdata, setselectedprojectdata] = useState("")
        const [selectedstatus, setselectedstatus] = useState("All")
        const [projectpopup, setprojectpopup] = useState(false)
        const [statuspopup, setstatuspopup] = useState(false)
        const [loading, setloading] = useState(false)
        const [selecteddate, setselecteddate] = useState(new Date())

        const [startdate, setstartdate] = useState(new Date())
        const [enddate, setenddate] = useState(new Date())
        const [slotdata, setslotdata] = useState([])
        const [selectedslot, setselectedslot] = useState()
        const [empdetailsvisible, setempdetailsvisible] = useState(false)

        const [selectedshifttype, setselectedshifttype] = useState("Worker");
        const [employeepopup, setemployeepopup] = useState(false);

        const navigate = useNavigate();

useEffect(()=>{
  let startingdate = new Date()
  startingdate.setMonth(startingdate.getMonth() - 3)
  let endingdate = new Date()
  endingdate.setMonth(endingdate.getMonth() + 3)
  setstartdate(startingdate)

  setenddate(endingdate)
},[])

        useEffect(()=>{
          if(selectedshifttype == "Worker"){
            getallworkerslotsfortoday()
          }else{
            getallcleanerslotsfortoday()
          }

        },[selecteddate, selectedprojectdata, selectedshifttype])

        const getallworkerslotsfortoday = async () => {
          let assignedprojects = user?.projectassigned || []
          let projectidlist = []
          for (const key in assignedprojects){
            projectidlist.push(assignedprojects[key].project)
          }

            setloading(true)
            await getallemployeesslotsdata("slots", selectedprojectdata?.id, moment(selecteddate).format("YYYY-MM-DD"), projectidlist).then(async (response) => {

              let dataobj = [...response]
              for (const key in dataobj) {
                dataobj[key]["workerstatus"] = dataobj[key]?.checkoutdatetime ? "Checked Out" : dataobj[key]?.checkindatetime ? "Checked In" : dataobj[key]?.onthewaytime ? "On The Way" : "Pending"
              }

              setslotdata(dataobj)

              setloading(false)
            }).catch((err) => {
                setloading(false)
              console.log("err" + err)
            })
          }

          const getallcleanerslotsfortoday = async () => {
            let assignedprojects = user?.projectassigned || []
            let projectidlist = []
            for (const key in assignedprojects){
              projectidlist.push(assignedprojects[key].project)
            }
            setloading(true)
            await getallcleanerslotsdata("cleaningshifts", selectedprojectdata?.id, projectidlist).then(async (response) => {



                  let responsedata = [...response]
          for (const index in responsedata) {

            responsedata[index]["employeedata"] = []
            for (const key in responsedata[index].employees){
              responsedata[index].employeedata.push({
              name: employees.filter(function (data) { return data.id == responsedata[index].employees[key] }).map(function (data) { return data.firstname + " " + data.lastname }),
              profilepicture: employees.filter(function (data) { return data.id == responsedata[index].employees[key] }).map(function (data) { return data.profilepicture }),
              })
            }
          }
              setslotdata(responsedata)
              setloading(false)
            }).catch((err) => {
                setloading(false)
              console.log("err" + err)
            })
          }

 function filters (title, val, onpress) {
    return(
        <div className='w-full md:w-[30%] mt-[20px]' >
        <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>{title}</p>
     <div className='relative'>
        <div
        onClick={onpress}
          className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-md border border-themeColor items-center flex h-[40px]" >
{val}
          </div>
          <ProjectPopUp

visible ={projectpopup && title == "Projects"}
data = {[...[{title: "All"}],  ...clients]}

activateoutsidecontainerclick = {()=>{
    setprojectpopup(false);
}}

            onClickcorrect={(item) => {
                if(item.title == "All"){
                    setselectedproject(item.title)
                    setselectedprojectdata("")
                    setprojectpopup(false);
                }else{
                    setselectedproject(item.title)
                    setselectedprojectdata(item)
                    setprojectpopup(false);
                }

            }}
/>
<StatusPopup

visible ={statuspopup && title == "Status"}
data = {checkinstatusfilter}

activateoutsidecontainerclick = {()=>{
    setstatuspopup(false);
}}

            onClickcorrect={(item) => {
                setselectedstatus(item.title)
                setstatuspopup(false);
            }}
/>
          </div>
      </div>
    )
}

function todaysdate(){
    return(
        <div className='flex flex-row w-full items-center justify-center relative'>
               <div className='flex flex-row w-full items-center justify-start relative'>
    <div>
        <FaRegCalendarAlt
        className='text-[30px] text-themeColor'/>
    </div>
<div className='ml-[5px]'>
<p className='PoppinsRegular font-medium text-black text-sm'>{moment(new Date()).format("DD MMM yyyy")}</p>
<p className='PoppinsRegular font-medium text-lightgrey text-sm'>{daysofweek[moment(new Date()).day()].day}</p>
    </div>
    </div>
    <img src={user.profilepicture} className='position absolute h-[120px] w-[120px] rounded-full object-cover top-[-80px]' />
</div>
    )
}


const selectedDay = (val) =>{
    setselecteddate(val)
};

    return (
        <div className='w-[100%] lg:flex  items-center  flex-col  mt-[70px] md:mt-[0px] '>
             {selectedprojectdata !== "" ?
                <img src={selectedprojectdata.logo} className='w-full h-[300px] rounded-md object-cover' />
:
<div className='w-full min-h-[300px] rounded-md bg-themeColor flex items-center justify-center'>
<p className='PoppinsRegular font-medium text-white text-[25px]'>Projects</p>
</div>
             }
<div className='w-full flex justify-start mt-[10px]'>
{todaysdate()}
</div>

      <div className='flex flex-row items-center w-full justify-center mt-[40px]'>
      <p
        onClick={() => { setselectedshifttype("Worker") }}
        className={`PoppinsRegular text-[14px] font-weight: 400  hover:cursor-pointer hover:underline  ${selectedshifttype == "Worker" ? "text-themeColor font-medium text-[15px]" : "text-black"}`} >Worker Shifts</p>
      <p className='mx-[10px]'>|</p>
      <p
        onClick={() => { setselectedshifttype("Cleaner")}}
        className={`PoppinsRegular text-[14px] font-weight: 400  hover:cursor-pointer hover:underline  ${selectedshifttype == "Cleaner" ? "text-themeColor font-medium text-[15px]" : "text-black"} `}>Cleaner Shifts</p>
    </div>

<div className='flex flex-col md:flex-row w-full justify-evenly mt-[20px] md:mt-[40px]'>
{filters("Projects", selectedproject, ()=>{setprojectpopup(!projectpopup)})}

{selectedshifttype == "Worker" &&
filters("Status", selectedstatus,  ()=>{setstatuspopup(!statuspopup)})
}


</div>

{selectedshifttype == "Worker" &&
<div className='w-full pt-[20px]'>
{/* <DatePicker getSelectedDay={selectedDay}
                  selectDate={selecteddate}
                  endDate={180}
                  enableScroll={true}
                  startingDate = {new Date(2023, 0, 1)}
                  // startDate={180}
                  // selectedDate={selecteddate}
                  // startDate={new Date(2023, 0, 1)}
                  // endDate={new Date(31, 12, 2024)}
                  labelFormat={"MMMM yyyy"}
                  color={"#1F2E5E"}

/> */}

<HorizontalDatePicker startDate={startdate} endDate={enddate} selectedDate = {selecteddate} onDateSelected={selectedDay} />
</div>
}


<div className='w-full py-[20px] flex flex-col items-center justify-center'>


{loading ?
<div className='py-[80px]'>
   <RotatingLines
   visible={true}
   height="25"
   width="25"
   strokeColor="#218fdb"
   color="grey"
   strokeWidth="5"
   animationDuration="0.75"
   ariaLabel="rotating-lines-loading"
   wrapperStyle={{}}
   wrapperClass=""
   />
   </div>
    :


        slotdata.length == 0 ?
        <div className='flex flex-col w-full py-[80px] items-center justify-center'>

      <p  className='PoppinsRegular font-medium text-lightgrey '>{selectedshifttype == "Worker" ? "No Slots Avaialble" : "No Cleaner Shifts Available"}</p>
        <ImFilesEmpty
        className='text-[40px] text-lightgrey pt-[5px]'
        />
        </div> :

        selectedshifttype == "Worker" ?
    slotdata.map((item, index) => {

      const checkout = new Date(item.checkoutdatetime)
      const endtime = new Date(item.endtimetimestamp);
      const endtimePlusTwoMinutes = new Date(endtime.getTime() + 2 * 60 * 1000);
      const latecheckout = checkout > endtimePlusTwoMinutes
      return(
            item.status == "Accepted" && (item.workerstatus == selectedstatus || selectedstatus == "All") &&
            employees.filter(function (data) { return data.id == item.employeeid }).map(function (data) {



              return (


                <div
                key= {index.toString()}
                  onClick={() => {
                    setselectedslot({
                      item: item,
                      recordid: item.recordid,
                      id: data.id,
                      picture: data.profilepicture,
                      name: data.firstname + " " + data.lastname,
                      designation: item.projectdesignation,
                      phonenumber: data.phonenumber,
                      starttime: item?.starttime,
                      endtime: item?.endtime,
                      selecteddate: moment(selecteddate).format("YYYY-MM-DD")
                    })
                    setempdetailsvisible(true)


                  }}

                  className={`w-full  flex flex-row justify-between items-center border border-t-0 border-r-0 border-l-0 border-b-1 border-b-gray-200 px-[10px] py-[10px] hover:cursor-pointer hover:bg-[#0989B826]/10 rounded-md  ${item?.hoursapproved != false && item?.late != true &&   latecheckout != true ? "bg-white" :'bg-[rgba(255,0,0,0.1)]'}`}
                  >


<div className='w-full  flex flex-row items-center'>
    <div className='relative'>
 <img src={  data.profilepicture} className='w-[50px] h-[50px] object-cover rounded-full' />
 {item?.hoursapproved != undefined &&
<div className='position absolute bottom-[-2px] right-[-5px] h-[20px] w-[20px] rounded-full bg-[#F9F9F9] flex items-center justify-center'>
 {item?.hoursapproved == true &&
     <IoMdCheckmark
     className='text-green-500'/>
 }
  {item?.hoursapproved == false &&
     <RxCross2
     className='text-red-500'/>
 }
 </div>}
       </div>
                    <div
                    className='ml-[20px] flex flex-col w-full'
                 >
                    <div   className='flex flex-row'>
                      <p
                     className='PoppinsRegular font-medium text-black text-[15px]'>{data.firstname.toUpperCase() + " " + data.lastname.toUpperCase() + " as  "}</p>
                     <p className='PoppinsRegular font-medium text-themeColor ml-[5px] text-[15px]'>{ item.projectdesignation}</p>
                     <p className='PoppinsRegular font-medium text-black ml-[5px] text-[15px]'>{ item?.tasks?.length > 0 ? "(" + item?.tasks.length + " Tasks)" : "" }</p>

                     </div>
                      <p
                       className={`PoppinsRegular font-medium text-[15px] ${item?.checkoutdatetime ? "text-red-500" : item?.checkindatetime ? "text-themeColor" : "text-orange-500"} `}>{item?.checkindatetime != undefined && item?.checkoutdatetime == undefined  ? item?.workerstatus + " - " + moment(item?.checkindatetime).format("HH:mm") : item?.workerstatus}</p>
                      <p  className={`PoppinsRegular font-medium ${ item?.checkoutdatetime ? "text-green-500" : "text-lighttextGray"}  text-[15px]`}>{item?.checkoutdatetime ? moment(item?.checkindatetime).format("HH:mm") + " - " +  moment(item?.checkoutdatetime).format("HH:mm")   : item.timeslot}</p>
                    </div>
</div>

                 <IoIosArrowForward
                 className='text-[20px] text-lightgrey'
                 />
                </div>



              )
            })
          )
})

        :
        <table className="  w-[90%]  divide-y-2 divide-gray-200 text-sm mt-[20px]   px-3 lg:px-7">
        <thead className="ltr:text-left rtl:text-right">


<tr>
          <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Date</th>
          <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Time Slot</th>
          <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">No Of Employees</th>
          <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Designation</th>
          <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 "></th>

          </tr>

        </thead>
        <tbody className="divide-y divide-gray-200 text-center">

        {slotdata.map((item, index) =>
          (
            <>
            <tr

            key = {index.toString()}
            className='hover:bg-[#0989B826]/10 cursor-pointer '>
<td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-lighttextGray">
                              {item.startdate + " - " + item.enddate}

                            </td>
<td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-lighttextGray">
                              {item.timeslot}

                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-black">
                              {item?.employees?.length}

                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-themeColor">
                              {item.projectdesignation}

                            </td>
                            <td className='px-[15px] mr-[30px] py-2'>
                            <button
                      onClick={() => {

                        navigate('/CreateEmployeeSchedule', {
                          state: {
                            projectid: item.projectid,
                            cleanershiftedit: true,
                            cleaningshiftdata: item
                          }
                        });

                      }}
                      type='submit' className="text-center w-[120px] py-2 rounded-md bg-themeColor text-white text-sm PoppinsRegular uppercase font-medium ">Add Employee</button>
</td>

</tr>


</>
          ))}
          </tbody>
        </table>


}
</div>

<EmployeeSlotPopUp
visible = {empdetailsvisible}
data = {selectedslot}

activateoutsidecontainerclick = {()=>{
 setempdetailsvisible(false)
}}

onapprovalsuccess={(id, bool) => {
    let allempdata = [...slotdata]
    let empindex = allempdata.findIndex(data => {
      return data.recordid == id;
    });
    allempdata[empindex]["hoursapproved"] = bool

    setslotdata(allempdata)

}}

filtertasks ={(id, tasks) => {
  let allempdata = [...slotdata]
  let empindex = allempdata.findIndex(data => {
    return data.recordid == id;
  });
  allempdata[empindex].tasks = tasks
  setslotdata(allempdata)

}}



/>



        </div>
    )
}

export default Slots;