import { useEffect, useRef, useState } from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import { getAllCleanerShifts, getuserinformation, updateItem } from "../../utils/firebasefunctions";
import moment from "moment";
import { RotatingLines } from 'react-loader-spinner'
import { IoMdCheckmark } from "react-icons/io";
import { IoIosCloseCircle } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { ToastContainer, toast } from "react-toastify";
import { FaCircleMinus } from 'react-icons/fa6';
import { arrayUnion } from "firebase/firestore";
import { storage } from '../../firebase.config'
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable
} from "firebase/storage";

const EmployeeSlotPopUp=(props) => {
    const [employeedata, setemployeedata] = useState()

    const [loading, setloading] = useState(false)
    const [taskloader, settaskloader] = useState(false)
    const [newtask, setnewtask] = useState("")
    const [newtaskimage, setnewtaskimage] = useState("")
    const [newtaskimagedata, setnewtaskimagedata] = useState("")
    const popupref = useRef(null)
    const fileInput = useRef();
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (popupref.current && !popupref.current.contains(event.target)) {
        props.activateoutsidecontainerclick()
          }


        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [popupref]);

useEffect(()=>{
    if( props.visible){
        getusersactivitylogs(props.data.recordid)
    }
    setnewtask("")

}, [props.data, props.visible])



async function updatemultiusers( cleaningshiftid, sheduleddate) {

  let data = {
    tasks: newtask,
    completed: false
    }

    if(newtaskimagedata != ""){
    const imageFile = newtaskimagedata.target.files[0]
    const storageRef = ref(storage, `taskpicture/${Date.now()}-${imageFile.name}`)
    const uploadTask =  uploadBytesResumable(storageRef, imageFile)
     uploadTask.on('state_changed', (snapshot) => {
    }, (error) => {
        toast.error("upload error " + error.message);
    }, async () => {
      await getDownloadURL(uploadTask.snapshot.ref).then(async downloadURL => {
          data["taskimage"] = downloadURL

          let taskdata = {
            tasks: arrayUnion(data)
          }
          await getAllCleanerShifts("slots", cleaningshiftid, sheduleddate ).then(async (response) => {
        for (const key in response){

          await updateItem("slots", response[key].schedulecreationdate, taskdata).then(async (response) => {

              let newdata = {...employeedata}
        let oldtasks = employeedata?.tasks
        oldtasks.push(data)

        setnewtaskimage("")
        setnewtaskimagedata("")
        setnewtask("")
         settaskloader(false)




          }).catch((err) => {
            alert(err)
            settaskloader(false)
          })
        }
          }).catch((err) => {
            alert(err)
            settaskloader(false)
          })
        })
        .catch((error) => {
            toast.error(error.message);
        });
      })
    }else{
      let taskdata = {
        tasks: arrayUnion(data)
      }
      await getAllCleanerShifts("slots", cleaningshiftid, sheduleddate ).then(async (response) => {
    for (const key in response){

      await updateItem("slots", response[key].schedulecreationdate, taskdata).then(async (response) => {

          let newdata = {...employeedata}
    let oldtasks = employeedata?.tasks
    oldtasks.push(data)

    setnewtaskimage("")
    setnewtaskimagedata("")
    setnewtask("")
     settaskloader(false)




      }).catch((err) => {
        alert(err)
        settaskloader(false)
      })
    }
      }).catch((err) => {
        alert(err)
        settaskloader(false)
      })
    }


}

async function getusersactivitylogs(recordid){
    await getuserinformation("slots", recordid).then(async(response) => {
    setemployeedata(response)
    }).catch((err) => {
      alert(err)
    })
  }


  const handleimageChange = (event) => {
    fileInput.current.click();
}


const handleimageselected = (e) => {
  setnewtaskimagedata(e)
  setnewtaskimage(URL.createObjectURL(e.target.files[0]));
}
  const Logs = (time, log) => {
    return(
        <div className="w-full flex flex-row">
                    <p className="PoppinsRegular font-medium text-lightgrey text-[15px]">{time}</p>
                    <p className="ml-[10px] PoppinsRegular font-medium text-black text-[15px]">{log}</p>
        </div>

    )
  }

  const ShiftStatus = () => {
    return(
        <div className="w-full py-[20px] px-[15px]">
            <div className="w-full flex flex-row justify-between">
                    <p className="PoppinsRegular font-medium text-black text-[15px]">Date:</p>
        <p className="PoppinsRegular font-medium text-black text-[15px]">{props.data.selecteddate}</p>
        </div>
        <div className="w-full flex flex-row justify-between">
                    <p className="PoppinsRegular font-medium text-black text-[15px]">Shift Start Time:</p>
        <p className="PoppinsRegular font-medium text-black text-[15px]">{props.data.starttime ? props.data.starttime : "Not Assigned"}</p>
        </div>
        <div className="w-full flex flex-row justify-between">
                    <p className="PoppinsRegular font-medium text-black text-[15px]">Shift End Time:</p>
        <p className="PoppinsRegular font-medium text-black text-[15px]">{props.data.endtime ? props.data.endtime : "Not Assigned"}</p>
        </div>
        {employeedata?.checkoutdatetime != undefined &&
        <div className="w-full flex flex-row justify-between">
                    <p className="PoppinsRegular font-medium text-black text-[15px] flex flex-row">Hours Worked<p className={`${employeedata?.hoursapproved == true ? "text-green-500" : employeedata?.hoursapproved == false ?  "text-red-500" : "text-black"}  ml-[5px] text-[14px]`}>{`${employeedata?.hoursapproved == true ? " (Approved):" : employeedata?.hoursapproved == false ? " (Rejected):" : ":"}`}</p></p>
        <p className={`PoppinsRegular font-medium  text-[15px] ${employeedata?.hoursapproved == true ? "text-green-500" : employeedata?.hoursapproved == false ?  "text-red-500" : "text-black"}`}>{Math.floor(((employeedata?.checkoutdatetime - employeedata?.checkindatetime) / (1000 * 60 * 60)) % 24) + " hrs "  + Math.floor(((employeedata?.checkoutdatetime - employeedata?.checkindatetime) / 1000 / 60) % 60) + " mins"}</p>
        </div>
  }


{employeedata?.tasks != undefined && employeedata?.tasks.length > 0 &&
<>

<p className="pt-[10px] PoppinsRegular font-medium text-black">Tasks:</p>
        {employeedata?.tasks.map((item, ind) => {
            return (
              <div className="w-full flex flex-col">
<div className="w-full flex flex-row ">

<div className="flex w-full flex-row items-center">
  {!item.completed && !item.rejected &&
<FaCircleMinus

onClick={async ()=>{

  let data = {...employeedata}
  data.tasks = data?.tasks.filter(function (itemdata, innerkey) {
    return innerkey != ind
  })
  setemployeedata(data)
  await updateItem('slots', props.data.recordid, data)
  .then(async response => {
    if (response == "success") {



      props.filtertasks(props.data.recordid, data.tasks)




    } else {
      toast.warn("Something went wrong. Please try again later!")

    }
  })
  .catch(error => {


    toast.warn("Something went wrong. Please try again later!")
  });


}}
className=" text-red-500 w-[13px] h-[13px] hover:cursor-pointer mr-[10px]"
/>
        }
<p className=" PoppinsRegular font-medium text-black text-[15px]">{item.tasks}</p>
<p className=" PoppinsRegular font-medium text-red-500 text-[12px] ml-[5px]">{item?.late && employeedata?.shifttype != "Cleaner" ? " (Late)" : null}</p>
{/* <p className="PoppinsRegular font-medium text-black text-[15px]">{`deadline: ${moment(item.deadlinetimestamp).format('hh:mm')}`}</p> */}
</div>
<div className="flex ">
<p className={`PoppinsRegular font-medium ${item.completed ? "text-green-500" : item.rejected ? "text-red-500": "text-orange-500"} text-[15px]`}>{item.completed ? "Completed" : item.rejected ? "Rejected" : "Pending"}</p>
</div>

  </div>
{item?.rejectionimage != "" && item?.rejectionimage != undefined &&
  <img src={item?.rejectionimage} className='w-[200px] h-[100px]  object-cover my-[5px]' />
        }
  {item?.rejectionreason &&
  <p className=" PoppinsRegular font-medium text-red-500 text-[15px]">{item?.rejectionreason}</p>
        }
  </div>
            )
        })}
        </>
      }

{props.data?.item?.shifttype == "Cleaner" && employeedata?.checkoutdatetime == undefined &&
<>

{newtaskimage &&
<div className="flex w-full justify-center mt-[15px]">
  <div className="flex relative w-[110px] h-[110px]">
  <div
                        onClick={() => {
                          setnewtaskimage("")
                          setnewtaskimagedata("")
                        }}
                        className='bg-themeColor h-[25px] w-[25px]  rounded-full flex  items-center justify-center position absolute right-[-5px] top-[-5px]'>
                        <IoIosCloseCircle

                          className=" text-white  w-[15px] h-[15px] hover:cursor-pointer"
                        /></div>

   <img src={newtaskimage} className='w-[100%] h-[100%] object-cover rounded-md ' />
   </div>
   </div>
   }
<input type="file" style={{ "display": "none" }} ref={fileInput} onChange={handleimageselected} />
              <textarea type='text' placeholder= "Enter task..."
                    rows={1}
                                        value={newtask}
                                        onChange={(event)=>{
                                          setnewtask(event.target.value)
                                        }}
                                        className={` px-4 ${newtask == "" ? " text-gray-500 " : "text-textcolor"} text-sm font-medium PoppinsRegular mt-[10px]  w-full h-[65px] rounded-xl border border-themeColor items-center flex `}/>
                               <div className="w-full flex justify-end  mt-[10px]">
                                {taskloader ?
                                <div className=''>
                                <RotatingLines
                                visible={true}
                                height="25"
                                width="25"
                                strokeColor="#218fdb"
                                color="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                ariaLabel="rotating-lines-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                />
                                </div>
                                :
                                <div className="flex flex-row ">
                                                                      <div
                                    onClick={handleimageChange}
                                        className={`rounded-md w-[90px] h-[30px] bg-themeColor flex items-center justify-center hover:cursor-pointer`}>
<p className=" PoppinsRegular font-medium text-white text-[12px] ">Add Image</p>
</div>
                                        <div
                                        onClick={()=>{
                                          if(newtask != ""){
                                            settaskloader(true)


                                              updatemultiusers( props.data?.item?.cleaningshiftid, props.data?.item?.sheduleddate)

                                          }
                                        }}
                                        className={`ml-[5px] rounded-md w-[70px] h-[30px] ${newtask != "" ? "bg-themeColor" : "bg-lighttextGray"} flex items-center justify-center hover:cursor-pointer`}>
<p className=" PoppinsRegular font-medium text-white text-[12px] ">Add Task</p>
</div>
</div>
  }
</div>
</>
  }


        <p className="pt-[10px] PoppinsRegular font-medium text-black">Today's Shift Logs:</p>
        {employeedata == undefined &&
            <p className="PoppinsRegular font-medium text-black text-[15px]">Not Available</p>
          }

{employeedata?.onthewaytime != undefined &&
  Logs(moment(employeedata?.onthewaytime).format("H:mm"), "Marked on the way")
}
{employeedata?.checkindatetime != undefined &&
  Logs(moment(employeedata?.checkindatetime).format("H:mm"), "Checked In")
}
{employeedata?.lunchindatetime != undefined &&
  Logs(moment(employeedata?.lunchindatetime).format("H:mm"), "Lunch In")
}
{employeedata?.lunchoutdatetime != undefined &&
  Logs(moment(employeedata?.lunchoutdatetime).format("H:mm"), "Lunch Out")
}
{employeedata?.checkoutdatetime != undefined &&
  Logs(moment(employeedata?.checkoutdatetime).format("H:mm"), "Checked Out")
}
</div>







    )
  }


  const Approvalbutton = (type) => {
    return(
  <div
  onClick={async ()=>{


    setloading(true)
    let data = {
      hoursapproved: type == "cross" ? false : true
    }
    await updateItem('slots',  props.data.recordid, data)
    .then(async response => {
      if (response == "success") {

  let dataset = {...employeedata}
  dataset["hoursapproved"] = data.hoursapproved
  setemployeedata(dataset)

  props.onapprovalsuccess(props.data.recordid, data.hoursapproved)


        setloading(false)

      } else {
        setloading(false)
        toast.warn("Something went wrong. Please try again later!")

      }
    })
    .catch(error => {
      setloading(false)

      toast.warn("Something went wrong. Please try again later!")
    });
  }}
  className="h-[40px] w-[40px] flex items-center justify-center  hover:cursor-pointer"
>

{type == "check" ?
     <IoMdCheckmark
     className='text-green-500 text-[40px]'/>
 :

     <RxCross2
     className='text-red-500 text-[40px]'/>
 }


  </div>
    )
  }
    return(
        props.visible &&
        <div

        className = "position absolute z-10  top-[0] left-[0] w-full h-full bg-[rgba(211,211,211,0.4)] flex items-center justify-center "
        >
        <div
               ref={popupref}
        className="w-[400px]   bg-white border border-gray-300  rounded-xl  gk-scr  flex items-center justify-center flex-col overflow-hidden max-h-[90%] " >

  <img src={props.data.picture} className='w-full h-[200px]  object-cover  ' />
<div className=" flex w-[400px] items-center justify-start flex-col overflow-hidden max-h-[80%] overflow-y-scroll">
<div className="w-full flex flex-row justify-between items-start pt-[15px]  px-[15px]">
<div className="flex flex-col ">
  <p className="PoppinsRegular font-medium text-black">{props.data.name}</p>
  <p className="PoppinsRegular font-medium text-lightgrey text-[14px]">{props.data.designation}</p>
  </div>
  <p className={`PoppinsRegular font-medium text-black ${employeedata?.checkoutdatetime ? "text-red-500" : employeedata?.checkindatetime ? "text-themeColor" : "text-orange-500"}`}>{employeedata?.checkoutdatetime ? "Checked Out" : employeedata?.checkindatetime ? "Checked In" : employeedata?.onthewaytime ? "On The Way" : "Pending"}</p>
  </div>
  {ShiftStatus()}

  { employeedata?.hoursapproved != true  && employeedata?.checkindatetime != undefined && employeedata?.checkoutdatetime != undefined &&
       <>
           {loading ?

<div className='py-[20px]'>
<RotatingLines
visible={true}
height="25"
width="25"
strokeColor="#218fdb"
color="grey"
strokeWidth="5"
animationDuration="0.75"
ariaLabel="rotating-lines-loading"
wrapperStyle={{}}
wrapperClass=""
/>
</div>:
                  <div className="flex flex-row w-full justify-evenly px-[30%] pb-[20px]">
        { employeedata?.hoursapproved != false &&
        <>
            {Approvalbutton("cross")}
            </>}
            {Approvalbutton("check")}
            </div>}


            </>

}
  {/* <p className="text-center">{props.text}</p>

<div className="flex w-full justify-between items-center flex-row mt-[10px]">
<button
                    onClick={() => {
props.accept()
                    }}
                    type='submit' className='text-center py-2 rounded-md  bg-themeColor hover:bg-themeColor/80 w-[45%]  mx-auto text-white text-sm font-semibold PoppinsRegular uppercase'>
                    Yes
                </button>
                <button
                    onClick={() => {
                        props.cancel()
                    }}
                    type='submit' className='text-center py-2 rounded-md  bg-lighttextGray hover:bg-lighttextGray/80 w-[45%]  mx-auto text-white text-sm font-semibold PoppinsRegular uppercase'>
                  No
                </button>
</div> */}
</div>
        </div>
        </div>
    )
}


export default EmployeeSlotPopUp